import { useCallback, useEffect, useMemo, useState } from "react";

import { AnalyticsCode, QuestionAnalyticsCode } from "../../../../../__generated__/graphql";
import { DimensionGroupConfiguration } from "../../../../../shared/components/charts/types";
import { OrgData } from "../../../hooks/useOrgData";
import { useUrlParams } from "../../../hooks/useUrlParams";
import { defaultGroups } from "../split";

export interface SplitSettings {
  optional: boolean;
  analyticsCode: AnalyticsCode | null;
  questionId: string | null;
  setAnalyticsCode: (code: AnalyticsCode) => void;
  setQuestionId: (id: string) => void;
  clear: () => void;
  // Even if the split is optional and unset, we set this to {basis:NONE} because it's just easier:
  groups: DimensionGroupConfiguration;
  setGroups: (groups: DimensionGroupConfiguration) => void;
}

export type Question = OrgData["questions"][number];

export default function useUrlSplitDimension<K extends string>(
  name: K,
  optional: boolean,
  allQuestions: Question[],
): SplitSettings {
  const { getParam, setParam } = useUrlParams<K>();

  // Read the parameter naively:
  const param = getParam(name);
  const { analyticsCode, questionId, questions } = useMemo(() => {
    let analyticsCode: AnalyticsCode | null;
    let questionId: string | null;
    let questions: Question[];
    if (!param) {
      analyticsCode = null;
      questionId = null;
      questions = [];
    } else if (param.startsWith("custom-")) {
      analyticsCode = null;
      questionId = param.substring(7);
      questions = allQuestions.filter((q) => q.id === questionId);
    } else {
      analyticsCode = param as AnalyticsCode;
      questionId = null;
      questions = allQuestions.filter((q) => q.analyticsCode === analyticsCode);
    }
    if (questions.length || analyticsCode === "FORM" || analyticsCode === "OUTCOME") {
      return { analyticsCode, questionId, questions };
    }
    if (optional) return { analyticsCode: null, questionId: null, questions: [] };
    return defaultSplit(allQuestions);
  }, [allQuestions, optional, param]);

  // Create the groups
  // Grouping information won't fit in URL parameters so store it in state.
  // A future ticket exists for persisting this data.
  // At that point it might make sense to have a context provider for all the config data.
  // For now we can pass them around classically
  // which is a little inconsistent with the other params but not a big problem.
  const [groups, setGroups] = useState<DimensionGroupConfiguration>({ basis: "NONE" });
  // Reset the grouping if the question selection changes
  useEffect(() => setGroups(() => defaultGroups(analyticsCode, questions)), [analyticsCode, questions]);

  const setAnalyticsCode = useCallback((code: AnalyticsCode) => setParam(name, code), [name, setParam]);
  const setQuestionId = useCallback((id: string) => setParam(name, `custom-${id}`), [name, setParam]);
  const clear = useCallback(() => setParam(name, null), [name, setParam]);

  return useMemo<SplitSettings>(
    () => ({
      optional,
      analyticsCode,
      questionId,
      groups,
      setGroups,
      setAnalyticsCode,
      setQuestionId,
      clear,
    }),
    [optional, analyticsCode, questionId, groups, setAnalyticsCode, setQuestionId, clear],
  );
}

export function defaultSplit(allQuestions: Question[]) {
  if (allQuestions.length === 0) {
    throw new Error("You have no questions in your form");
  }
  // Prefer the "incident type" questions
  const incidentType = allQuestions.filter((q) => q.analyticsCode === QuestionAnalyticsCode.IncidentType);
  if (incidentType.length > 0) {
    return {
      analyticsCode: AnalyticsCode.IncidentType,
      questionId: null,
      questions: incidentType,
    };
  }
  // If that doesn't exist, take any analytics code we can find
  const analyticsCode = allQuestions.find((q) => q.analyticsCode)?.analyticsCode ?? null;
  if (analyticsCode) {
    const questions = allQuestions.filter((q) => q.analyticsCode === analyticsCode);
    if (questions.length > 0) {
      return {
        analyticsCode: analyticsCode as string as AnalyticsCode,
        questionId: null,
        questions,
      };
    }
  }
  // OK, just take *any question*
  return {
    analyticsCode: null,
    questionId: allQuestions[0].id,
    questions: allQuestions.slice(0, 1),
  };
}
