import mixpanel from "mixpanel-browser";
import React, { useCallback, useMemo, useState } from "react";

import { FormCategory } from "../../../../../../../../__generated__/graphql";
import { InDetailChartBlockOptions } from "../../../../../../../../shared/block-editor-data/chart-block-types";
import { InDetailSplitDimension } from "../../../../../../../../shared/components/charts/types";
import Alert from "../../../../../../../../shared/components/design-system/Alert";
import Dialog from "../../../../../../../../shared/components/design-system/Dialog";
import LoadingSpinner from "../../../../../../../../shared/components/design-system/LoadingSpinner";
import {
  SectionFooter,
  SectionHeader,
} from "../../../../../../../../shared/components/design-system/component-groups/section-header-footer";
import errorImage from "../../../../../../assets/images/error.svg";
import { useAvailableAnalyticsCodes } from "../../../../../../graphql/values";
import { TimePeriodSettings } from "../../../../../../hooks/filtering/useTimePeriodFromUrlParams";
import { useConfirm } from "../../../../../../hooks/useAlert";
import useOrgData, { OrgData } from "../../../../../../hooks/useOrgData";
import { isEqual } from "../../../../../../hooks/useWarnAboutUnsavedChanges";
import { TimePeriod } from "../../../../../../timePeriods";
import AnalysisChartPanel from "../../../../../analytics/AnalyticsInDetailPage/AnalysisChartPanel";
import ChartActions from "../../../../../analytics/AnalyticsInDetailPage/AnalysisChartPanel/ChartActions";
import AnalyticsChartSelectionPanel from "../../../../../analytics/AnalyticsInDetailPage/AnalyticsChartSelectionPanel";
import { InDetailChartSettings, getTitle } from "../../../../../analytics/AnalyticsInDetailPage/chart-options";
import useInDetailQuery from "../../../../../analytics/AnalyticsInDetailPage/useInDetailQuery";
import { SplitSettings, defaultSplit } from "../../../../../analytics/AnalyticsInDetailPage/useUrlSplitDimension";
import { defaultGroups } from "../../../../../analytics/split";
import useSiteData from "../../../../../sites-management/useSiteData";
import Button from "../../../../Button";
import EmptyPanel from "../../../../EmptyPanel";
import TitleWithActionGroup from "../../../../PageHeader/TitleWithActionGroup";
import PermissionCheck from "../PermissionCheck";
import useCancelWithConfirm from "../useCancelWithConfirm";
import useChartTypeSettings from "../useChartTypeSettings";
import { useFilterSettings } from "../useFilterSettings";
import UnderlyingDataEditor from "./InDetailDataEditor";
import useStateSplitDimension from "./useStateSplitDimension";

export default function EditInDetailChartDialog({
  onOk,
  onCancel,
  defaultValue: defaultValueProp,
}: {
  onOk: (settings: InDetailChartBlockOptions) => void;
  onCancel: () => void;
  // Omit this prop to create the "insert" variant of the dialog:
  defaultValue?: InDetailChartBlockOptions;
}) {
  const { questions } = useOrgData();
  const site = useSiteData();
  const { allCodes } = useAvailableAnalyticsCodes(FormCategory.Reporting);
  const confirm = useConfirm();

  const insertMode = !defaultValueProp;
  const defaultValue = useMemo<InDetailChartBlockOptions>(() => {
    if (defaultValueProp) return defaultValueProp;
    const split = defaultSplit(questions);
    const groups = defaultGroups(split.analyticsCode, questions);
    return {
      chartType: "BAR" as const,
      filter: {},
      data: { categories: [] },
      firstSplit: { ...split, groups },
      secondSplit: null,
      options: {
        showNumbers: true,
        showOuterBars: true,
        showHeatMap: false,
        showPercentages: false,
        showNotAnswered: false,
        showZeroBars: true,
      },
      dataHasBeenEdited: false,
    };
  }, [questions, defaultValueProp]);

  const firstSplit = useStateSplitDimension(defaultValue.firstSplit, false, questions);
  const secondSplit = useStateSplitDimension(defaultValue.secondSplit, true, questions);
  const chartTypeSettings = useChartTypeSettings(defaultValue.chartType);
  const [chartOptions, setChartOptions] = useState(defaultValue.options);
  const filterSettings = useFilterSettings(defaultValue.filter);
  const [editedData, setEditedData] = useState(defaultValue.dataHasBeenEdited ? defaultValue.data : null);

  const timePeriodSettings = useMemo<TimePeriodSettings>(
    () => ({
      timePeriod:
        filterSettings.filter.createdAt?.start || filterSettings.filter.createdAt?.end
          ? {
              period: "SPECIFIC",
              specificStart: filterSettings.filter.createdAt?.start ?? undefined,
              specificEnd: filterSettings.filter.createdAt?.end ?? undefined,
            }
          : { period: "ALL_TIME" },
      setTimePeriod(period: TimePeriod) {
        if (period.period === "ALL_TIME") {
          filterSettings.setFilter({ ...filterSettings.filter, createdAt: undefined });
        } else if (period.period === "SPECIFIC") {
          filterSettings.setFilter({
            ...filterSettings.filter,
            createdAt: { start: period.specificStart, end: period.specificEnd },
          });
        } else {
          throw new Error("Unexpected time period setting");
        }
      },
      usePresets: false,
    }),
    [filterSettings],
  );

  const chartSettings = useMemo<InDetailChartSettings>(
    () => ({
      config: { allowHeatMap: false },
      chartOptions,
      setChartOptions,
      title: getTitle(allCodes, firstSplit),
      legendTitle: secondSplit.analyticsCode || secondSplit.questionId ? getTitle(allCodes, secondSplit) : "Legend",
    }),
    [allCodes, chartOptions, firstSplit, secondSplit],
  );

  const { loading, error, data } = useInDetailQuery(
    firstSplit,
    secondSplit,
    timePeriodSettings.timePeriod,
    chartSettings.chartOptions,
    chartTypeSettings.chartType,
    { ...filterSettings, filter: { ...filterSettings.filter, category: FormCategory.Reporting } },
    false,
    site.language,
  );

  const onCancelWithConfirm = useCancelWithConfirm(
    onCancel,
    [chartOptions, chartTypeSettings.chartType, filterSettings.filter],
    [defaultValue.options, defaultValue.chartType, defaultValue.filter],
    splitIsEqual(firstSplit, defaultValue.firstSplit),
    splitIsEqual(secondSplit, defaultValue.secondSplit),
  );

  const save = useCallback(() => {
    if (!data) return;
    mixpanel.track("In-Detail analytics inserted", {
      chartType: chartTypeSettings.chartType,
      firstSplit: firstSplit,
      secondSplit: secondSplit,
      options: chartSettings.chartOptions,
      filter: filterSettings.filter.questions?.length ? true : false,
    });
    onOk({
      chartType: chartTypeSettings.chartType,
      filter: filterSettings.filter,
      data: editedData ?? data,
      firstSplit: populateSplitDetails(firstSplit, questions),
      secondSplit: populateSplitDetails(secondSplit, questions),
      options: chartSettings.chartOptions,
      dataHasBeenEdited: !!editedData,
    });
  }, [
    chartSettings.chartOptions,
    chartTypeSettings.chartType,
    data,
    editedData,
    filterSettings.filter,
    firstSplit,
    onOk,
    questions,
    secondSplit,
  ]);

  const dataHasChanged = useMemo(() => {
    if (loading || insertMode || !data || defaultValue.dataHasBeenEdited) return false;
    if (isEqual(data, defaultValue.data)) return false;

    if (!splitIsEqual(firstSplit, defaultValue.firstSplit)) return false;
    if (!splitIsEqual(secondSplit, defaultValue.secondSplit)) return false;
    if (!isEqual(filterSettings.filter, defaultValue.filter)) return false;
    // Don't bother checking chartType or chartOptions, those are just view settings.
    return true;
  }, [data, defaultValue, filterSettings.filter, firstSplit, insertMode, loading, secondSplit]);

  const openDataEditor = useCallback(() => {
    setEditedData(data);
    mixpanel.track("Opened 'edit underlying data' page", { type: "in-detail" });
  }, [data]);

  const discardEditedData = useCallback(async () => {
    if (
      isEqual(data, editedData) ||
      (await confirm({
        title: "Discard edits to data?",
        children: "Returning to the chart builder will delete any changes you have made to the data.",
        okCaption: "Continue",
        okVariant: "danger",
        cancelCaption: "Cancel",
      }))
    ) {
      setEditedData(null);
      mixpanel.track("Closed 'edit underlying data' page", { type: "in-detail" });
    }
  }, [confirm, data, editedData]);

  return (
    <PermissionCheck close={onCancel}>
      <Dialog size="large" fullHeight isOpen onClose={onCancel} onClickClose={onCancelWithConfirm}>
        <SectionHeader title={insertMode ? "Insert analytics data" : "Edit analytics data"} />
        {editedData ? (
          <UnderlyingDataEditor
            value={editedData}
            onChange={setEditedData}
            firstSplit={firstSplit}
            secondSplit={secondSplit}
          />
        ) : (
          <>
            {dataHasChanged ? (
              <Alert
                variant="info"
                title="The live data has changed since you generated this chart."
                message="Clicking 'update' will update the chart in your report to reflect this."
              />
            ) : null}
            <AnalyticsChartSelectionPanel
              className="ds-my-7"
              firstSplit={firstSplit}
              secondSplit={secondSplit}
              filterSettings={filterSettings}
              datePickerSettings={timePeriodSettings}
              category={FormCategory.Reporting}
            />
            <ChartActions
              firstSplit={firstSplit}
              secondSplit={secondSplit}
              timePeriod={timePeriodSettings.timePeriod}
              chartSettings={chartSettings}
              data={data}
              chartTypeSettings={chartTypeSettings}
              container={null}
              loading={loading}
              config={chartSettings.config}
            />
            {loading ? (
              <LoadingSpinner />
            ) : error || !data ? (
              <>
                <TitleWithActionGroup title={chartSettings.title} headerLevel={3} />
                <EmptyPanel image={errorImage}>
                  <p>An error occurred loading this data</p>
                </EmptyPanel>
              </>
            ) : (
              <AnalysisChartPanel
                firstSplit={firstSplit}
                secondSplit={secondSplit}
                timePeriod={timePeriodSettings.timePeriod}
                chartSettings={chartSettings}
                data={data}
                chartTypeSettings={chartTypeSettings}
              />
            )}
          </>
        )}
        <SectionFooter>
          {editedData ? (
            <Button onClick={discardEditedData} variant="secondary">
              {insertMode ? "Back to chart builder" : "Go to chart builder"}
            </Button>
          ) : null}
          <Button onClick={onCancelWithConfirm} variant="ghost" className="ds-ml-auto">
            Cancel
          </Button>
          {!editedData && data?.categories.length ? (
            <Button onClick={openDataEditor} variant="secondary" disabled={loading || !!error}>
              Edit data
            </Button>
          ) : null}
          <Button onClick={save} disabled={loading || !!error}>
            {insertMode ? "Insert" : "Update"}
          </Button>
        </SectionFooter>
      </Dialog>
    </PermissionCheck>
  );
}

function splitIsEqual(a: SplitSettings, b: InDetailSplitDimension | null) {
  if (!a) return !b;
  if (!b) return false;
  if (a.analyticsCode !== b.analyticsCode) return false;
  if (a.questionId !== b.questionId) return false;
  if (!isEqual(a.groups, b.groups)) return false;
  return true;
}

type Question = OrgData["questions"][number];

function populateSplitDetails(split: SplitSettings, questions: Question[]): InDetailSplitDimension {
  return {
    ...split,
    questions: questions.filter((q) => q.id === split.questionId || q.analyticsCode === split.analyticsCode),
  };
}
