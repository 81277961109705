import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import React, { createContext, PropsWithChildren, useCallback, useContext, useMemo } from "react";
import { Outlet, useParams } from "react-router-dom";

import {
  FormCategory,
  GetOrgDataQuery,
  GetOrgDataQueryVariables,
  GetOrgSitesQuery,
  GetOrgsQuestionsQuery,
  GetOrgTeamsQuery,
  GetOutcomesQuery,
  Permission,
  SiteUrlStatus,
} from "../../../__generated__/graphql";
import Alert from "../../../shared/components/design-system/Alert";
import Select from "../components/design-system/Select";
import Loading from "../components/global-furniture/LoadingPage";
import { questionFields } from "../graphql/fragments";
import useSelf from "./useSelf";
import { useUrlParams } from "./useUrlParams";

export type OrgData = GetOrgsQuestionsQuery & GetOrgTeamsQuery & GetOutcomesQuery & GetOrgSitesQuery;

export type OrgDataPayload = OrgData & {
  organisation: Pick<GetOrgDataQuery["organisation"], "id" | "__typename" | "name">;
  isCrossOrg: boolean;
  refetch: () => Promise<unknown>;
  featureGates: string[];
  reloading: boolean;
  sites: Array<GetOrgSitesQuery["sites"][number] & { url: string | null }>;
};
// This is exported so we can stub out the org data in tests and Storybook:
// @ts-ignore - the default value will never be used.
export const OrgDataContext = createContext<OrgDataPayload>(null);

const QUESTIONS_QUERY = gql`
  query getOrgsQuestions {
    questions {
      ...questionFields
    }
  }
  ${questionFields}
`;
const TEAMS_QUERY = gql`
  query getOrgTeams {
    teams: assignableTeams {
      id
      name
      members {
        id
        name
      }
    }
  }
`;
const OUTCOMES_QUERY = gql`
  query getOrgOutcomes {
    outcomes {
      id
      name
      guidance
      suppressForAnalytics
    }
  }
`;
const SITES_QUERY = gql`
  query getOrgSites {
    sites {
      id
      name
      urls {
        id
        status
        host
        isCanonical
      }
      language
      hideReportPage
      forms {
        id
        name
        title
        category
      }
    }
  }
`;

const CROSS_ORG_QUERY = gql`
  query getOrgData($organisationId: ID!) {
    organisation(id: $organisationId) {
      id
      name
      questions {
        ...questionFields
      }
      teams {
        id
        name
        members {
          id
          name
        }
      }
      outcomes {
        id
        name
        guidance
        suppressForAnalytics
      }
      sites {
        id
        name
        urls {
          id
          status
          host
          isCanonical
        }
        language
        hideReportPage
        forms {
          id
          name
          title
          category
        }
      }
      featureGates
    }
  }
  ${questionFields}
`;

export function OrgDataProvider({ children }: PropsWithChildren<unknown>) {
  const self = useSelf();

  // Take the organisation ID from the URL if present, if not then take the query parameter, and if that's not present then use "null" which acts to take the user's organisation.
  const { organisationId: param } = useParams<"organisationId">();
  const { getParam } = useUrlParams<"org">();
  const organisationId = param ?? getParam("org");

  const questionsQuery = useQuery<GetOrgsQuestionsQuery>(QUESTIONS_QUERY, {
    skip: !!organisationId,
    context: { doNotBatch: true },
  });
  const teamsQuery = useQuery<GetOrgTeamsQuery>(TEAMS_QUERY, { skip: !!organisationId, context: { doNotBatch: true } });
  const outcomesQuery = useQuery<GetOutcomesQuery>(OUTCOMES_QUERY, {
    skip: !!organisationId,
    context: { doNotBatch: true },
  });
  const sitesQuery = useQuery<GetOrgSitesQuery>(SITES_QUERY, { skip: !!organisationId, context: { doNotBatch: true } });

  const crossOrgQuery = useQuery<GetOrgDataQuery, GetOrgDataQueryVariables>(CROSS_ORG_QUERY, {
    skip: !organisationId,
    variables: { organisationId: organisationId! },
    context: { doNotBatch: true },
  });

  const value = useMemo<OrgDataPayload | null>(() => {
    if (!organisationId) {
      if (!questionsQuery.data || !teamsQuery.data || !outcomesQuery.data || !sitesQuery.data) return null;
      return {
        ...{ ...questionsQuery.data, ...teamsQuery.data, ...outcomesQuery.data },
        sites: sitesQuery.data?.sites.map(findSiteUrl),
        organisation: self.org,
        isCrossOrg: false,
        featureGates: self.org.featureGates,
        refetch: () => questionsQuery.refetch(),
        reloading: questionsQuery.loading,
      };
    }

    if (!crossOrgQuery.data) return null;
    const { organisation } = crossOrgQuery.data;
    // Reshape the data to match the existing expected return type — this adds a few extra fields but they won't matter.
    return {
      organisation,
      sites: organisation.sites.map(findSiteUrl),
      questions: organisation.questions,
      outcomes: organisation.outcomes,
      teams: organisation.teams,
      isCrossOrg: true,
      featureGates: organisation.featureGates,
      refetch: () => crossOrgQuery.refetch(),
      reloading: crossOrgQuery.loading,
    };
  }, [crossOrgQuery, organisationId, questionsQuery, teamsQuery, outcomesQuery, sitesQuery, self.org]);

  if (questionsQuery.error || teamsQuery.error || outcomesQuery.error || sitesQuery.error || crossOrgQuery.error) {
    return (
      <Alert variant="danger" message="There was an error fetching the organisation data. Please refresh the page." />
    );
  }

  if (
    questionsQuery.loading ||
    teamsQuery.loading ||
    outcomesQuery.loading ||
    sitesQuery.loading ||
    crossOrgQuery.loading ||
    !value
  ) {
    return <Loading authState="loading" />;
  }

  return <OrgDataContext.Provider value={value}>{children}</OrgDataContext.Provider>;
}

export function OrgDataRoute({ children }: PropsWithChildren<unknown>) {
  return <OrgDataProvider>{children ?? <Outlet />}</OrgDataProvider>;
}

export default function useOrgData(): OrgDataPayload {
  return useContext<OrgDataPayload>(OrgDataContext);
}

export function useQuestions(category: FormCategory) {
  const { questions } = useOrgData();
  return useMemo(() => questions.filter((question) => question.category === category), [questions, category]);
}

export function OrgSwitcher({ permission }: { permission: Permission }) {
  const { getParam, setParam } = useUrlParams<"org">();
  const self = useSelf();
  const changeOrg = useCallback((selection: string) => setParam("org", selection), [setParam]);

  const orgs = self.crossOrganisationPermissions
    .filter((p) => p.permission === permission && p.org.id !== self.org.id)
    .map(({ org }) => org);
  orgs.unshift(self.org);

  if (orgs.length < 2) return null;

  return (
    <Select label="Choose organisation" hideLabel value={getParam("org") ?? self.org.id} onChange={changeOrg}>
      <option value="">Choose organisation</option>
      {orgs.map(({ id, name }) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))}
    </Select>
  );
}

function findSiteUrl(site: GetOrgSitesQuery["sites"][number]) {
  const host =
    site.urls.find((u) => u.isCanonical) ??
    site.urls.find((u) => u.status === SiteUrlStatus.CorrectlySetUp) ??
    site.urls[0];

  return { ...site, url: host ? `https://${host.host}` : null };
}
